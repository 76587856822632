import React from "react";
import { MoonLoader } from "react-spinners";

const Loading = props => {

  const { backgroundColor } = props;
  return (
    <div className="fixed left-0 top-0 right-36 bottom-0 flex items-center justify-center" style={{backgroundColor}}>
      <MoonLoader />
    </div>
  );
};

Loading.propTypes = {
  
};

export default Loading;