import React from "react";
import StructurePage from "../pages/StructurePage";

const App = () => {
  return (
    <div>
      <StructurePage />
    </div>
  );
};

App.propTypes = {
  
};

export default App;