import React, { useEffect, useRef } from "react";
import { DefaultPluginSpec } from "molstar/lib/mol-plugin/spec";
import { PluginContext  } from "molstar/lib/mol-plugin/context";
import { createPluginAsync } from "molstar/lib/mol-plugin-ui/index";
import Sidebar from "../components/Sidebar";
import { useState } from "react";
import { PluginCommands } from "molstar/lib/mol-plugin/commands";
import Loading from "../components/Loading";
import "molstar/build/viewer/molstar.css";

const StructurePage = () => {

  const useUI = true;

  const parentRef = useRef();
  const canvasRef = useRef();
  const [loading, setLoading] = useState(true);

  const init = async () => {
    let plugin;
    if (useUI) {
      plugin = await createPluginAsync(parentRef.current, DefaultPluginSpec());
    } else {
      plugin = new PluginContext(DefaultPluginSpec());
      plugin.initViewer(canvasRef.current, parentRef.current);
      await plugin.init();
    }
    
    const data = await plugin.builders.data.download({ url: "https://files.rcsb.org/view/1LOL.pdb" }, { state: { isGhost: true } });
    const trajectory = await plugin.builders.structure.parseTrajectory(data, "pdb");
    await plugin.builders.structure.hierarchy.applyPreset(trajectory, "default");

    if (!useUI) {
      const structure = plugin.managers.structure.hierarchy.current.models[0].structures[0].cell
      const components = {
        polymer: await plugin.builders.structure.tryCreateComponentStatic(structure, "polymer"),
        ligand: await plugin.builders.structure.tryCreateComponentStatic(structure, "ligand"),
        water: await plugin.builders.structure.tryCreateComponentStatic(structure, "water"),
      };

      const builder = plugin.builders.structure.representation;
      const update = plugin.build();
      builder.buildRepresentation(update, components.polymer, { type: "molecular-surface", typeParams: { alpha: 1 } }, { tag: "polymer" });
      await update.commit();

      const renderer = plugin.canvas3d.props.renderer;
      PluginCommands.Canvas3D.SetSettings(plugin, { settings: { renderer: { ...renderer, backgroundColor: useUI ? 0xffffff : 0x1f2937 } } });
    }

    setLoading(false);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex w-screen relative">
      {useUI ? (
        <div className="relative h-screen flex-grow">
          <div ref={parentRef} className="h-screen absolute left-0 right-0" />
        </div>
      ) : (
        <div ref={parentRef} className="flex-grow relative">
          <canvas ref={canvasRef} style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}} />
        </div>
      )}
      <Sidebar />
      {loading && <Loading backgroundColor={useUI ? "white" : "#1f2937"} />}
    </div>
  );
};

StructurePage.propTypes = {
  
};

export default StructurePage;